const axios = require("axios");

class Claim {
  constructor() {
    this.els = document.querySelectorAll(".fake-input");
    this.maxIndex = 0;
    this.init();
  }

  validate() {
    let valid = true;
    this.els.forEach((element) => {
      if (!element.value) {
        valid = false;
      }
    });
    return valid;
  }

  placeCursor() {
    let wasSet = false;
    this.els.forEach((element, i) => {
      if (!element.value && !wasSet) {
        element.focus();
        this.maxIndex = i;
        wasSet = true;
      }
    });
  }

  handleKeyup() {
    let self = this;
    this.els.forEach((element) => {
      element.addEventListener("keyup", function () {
        self.placeCursor();
        return;
      });
    });
  }

  handleBackspace() {
    let self = this;
    console.log(self);
    document.addEventListener("keydown", KeyCheck); //or however you are calling your method
    function KeyCheck(event) {
      var KeyID = event.keyCode;
      if (KeyID == 8 || KeyID == 46)
        if (!self.els[self.maxIndex].value) {
          self.els[self.maxIndex - 1].focus();
        }
    }
  }

  handlePaste() {
    let self = this;
    this.els.forEach((element) => {
      element.addEventListener("paste", function (event) {
        let paste = (event.clipboardData || window.clipboardData).getData(
          "text"
        );
        if (paste.length == 6) {
          for (let i = 0; i < paste.length; i++) {
            const element = paste[i];
            self.els[i].value = element;
          }
        }
      });
    });
  }

  init() {
    if (!this.els) {
      return;
    }
    this.handleKeyup();
    this.handleBackspace();
    this.handlePaste();
  }
}

export default Claim;
