class Forms {
  constructor() {
    this.init();
  }

  handleSubmit() {
    let form = document.querySelector(".validate");
    if (!form) {
      return;
    }
    let self = this;
    let submit = form.querySelector('[type="submit"]');

    form.addEventListener("submit", function (e) {
      if (!self.validate()) {
        e.preventDefault();
      }
    });
    submit.addEventListener("click", function (e) {
      if (!self.validate()) {
        e.preventDefault();
      }
    });
  }

  validate() {
    let valid = true;
    let form = document.querySelector(".validate");

    let els = form.querySelectorAll("[required]");
    els.forEach((element) => {
      if (!element.value) {
        valid = false;
        element.classList.add("error");
      } else {
        element.classList.remove("error");
      }
    });

    let passwordLength = document.querySelectorAll(".password");

    passwordLength.forEach((element) => {
      if (!element.value || element.value.length < 6) {
        element.classList.add("error");
        valid = false;
      } else {
        element.classList.remove("error");
      }
    });

    return valid;
  }

  togglePassword() {
    let els = document.querySelectorAll(".toggle-password");
    els.forEach((element) => {
      element.addEventListener("click", function () {
        let passwordEls = document.querySelectorAll(".password");
        passwordEls.forEach((element) => {
          if (element.getAttribute("type") == "password") {
            element.setAttribute("type", "text");
          } else {
            element.setAttribute("type", "password");
          }
        });
      });
    });
  }

  init() {
    this.togglePassword();
    this.handleSubmit();
  }
}

export default Forms;
