class Faq {
  constructor() {
    this.init();
  }

  init() {
    let items = document.querySelectorAll(".faq__item");
    items.forEach((item) => {
      item.addEventListener("click", function () {
        items.forEach((item) => {
          item.classList.remove("active");
        });
		
        item.classList.toggle("active");
      });
    });
  }
}

export default Faq;
