//import "./../css/_app.scss";
// import * as basicScroll from 'basicscroll'
import Aoe from "./vendor/aoe";

import Forms from "./Forms";
import Nav from "./Nav";
import Faq from "./Faq";
import Claim from "./Claim";

export default class App {
  constructor() {
    this.initDev(); // tests and shit
    this.initBody(); // runs on initialize & after barba
  }

  initBody() {
    const forms = new Forms();
    const aoe = new Aoe();
    aoe.init({});
  }

  initCore() {
    const nav = new Nav();
    const faq = new Faq();
    const claim = new Claim();
  }

  initDev() {}
}

function LoadApp() {
  const app = new App();
  app.initCore(); // runs on initialize
}

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", LoadApp);
} else {
  LoadApp();
}

jQuery(document).ready(function () {
  jQuery('.form__row--checkbox input').change(function () {
    if (this.checked) {
      jQuery(this).attr('value', 'true');
    } else {
      jQuery(this).attr('value', '');
    }
  });
});