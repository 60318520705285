class Nav {
	constructor() {
		this.init();
	}

	init() {
		
	}
}

export default Nav;